import { useMagicKeys } from '@vueuse/core'

export const useAppStore = defineStore('app', () => {
  const isPrimaryNavHidden = ref(true)
  const appIsBusy = ref(false)

  const route = useRoute()
  watch(
    () => route.fullPath,
    () => {
      checkRoute()
    }
  )

  onMounted(() => {
    checkRoute()
  })

  const checkRoute = function () {
    isPrimaryNavHidden.value =
      route.name === 'dashboard-company-questions-setId' ||
      route.name === 'dashboard-product-productId-questions-setId'
  }

  const showDebug = ref(useRuntimeConfig().public.debug.scores !== 'false')
  const keys = useMagicKeys()
  const toggleDebug = keys['Shift+Ctrl+Space']

  watch(toggleDebug, (v) => {
    if (v) {
      console.log('Toggle debug')
      showDebug.value = !showDebug.value
    }
  })

  return {
    isPrimaryNavHidden,
    appIsBusy,
    showDebug,
  }
})
